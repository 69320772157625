@import './src/helpers/constants';

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;

  .container {
    background-color: $background;
    width: calc(100% - 240px);
    height: 100vh;
    overflow: auto;

    &_inner {
      width: 100%;
      min-width: 1040px;
      padding: 36px 36px 0 36px;
      position: relative;
    }
  }
}

