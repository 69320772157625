@import './src/helpers/constants';

.add_organization {
  display: flex;

  &_info_text {
    margin-top: 80px;
  }

  &_button_group {
    display: flex;
    align-items: center;

    &_cancel {
      width: 160px;
      height: 48px;
      margin-right: 24px;
    }

    &_save {
      width: 160px;
      height: 48px;
    }
  }

  &_container {
    max-width: 376px;
    height: calc(100vh - 136px);
    background-color: #FFFFFF;
    margin-top: 74px;
    border-radius: 20px;
    padding: 8px 8px 0 8px;
    overflow-y: auto;

    &_descr {
      width: 360px;
      height: 132px;
      margin-bottom: 20px;
    }

    &_input {
      align-items: center;
      margin-bottom: 42px;
    }

    &_form_input {
      align-items: center;
      margin-bottom: 20px;
    }


    .schedule_section {
      width: 360px;

      &_row {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;


        &_box {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 360px;
          justify-content: space-between;

        }

        &_tag {
          display: flex;
          flex-direction: row;
          margin-top: 20px;

          &_item {
            background-color: $secondaryFill;
            margin-right: 12px;
            height: 32px;
            font-size: 13px;
            padding: 6px 12px 8px 12px;
            border: 1px solid $border;
            border-radius: 12px;
            transition: background-color .2s, color .2s, border .2s;

            &.pressed {
              background-color: #3545DE;
              color: #FFFFFF;
              cursor: default;
            }

            &:hover:not(.pressed) {
              cursor: pointer;
              background-color: $background;
              border: 1px solid $secondaryBorder;
            }

            &:active:not(.pressed) {
              background-color: $primaryBg;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &_day {
          font-size: 16px;
        }


        &_time {
          display: flex;

          &_wrapper_input:first-child {
            margin-right: 12px;
          }

          &_input {
            width: 80px;
          }
        }
      }

    }
  }

  &_temp_container {
    width: 376px;
    height: calc(100vh - 136px);
    background-color: #FFFFFF;
    margin-top: 74px;
    border-radius: 20px;
    padding: 8px 8px 0 8px;
    margin-left: 36px;

    .temp_container {
      height: 100%;

      &_title {
        font-family: Manrope-Light, sans-serif;
        font-size: 18px;
        margin-top: 16px;
        margin-bottom: 12px;
        text-align: center;
      }

      &_description {
        font-family: Manrope-Light, sans-serif;
        font-size: 13px;
        margin-top: 16px;
        margin-bottom: 12px;
      }

      &_scroll {
        height: calc(100% - 70px);
        overflow-y: auto;

        &.with_button {
          height: calc(100% - 123px);
          margin-bottom: 14px;
        }
      }

      &_item {
        margin: 8px 0;
        padding: 0 16px;
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 1 !important;
        transition: background-color .2s;

        &_text {
          font-family: Manrope-Light, sans-serif;
          font-size: 16px;
          transition: color .2s;
        }

        &_img {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        &:hover {
          background-color: $secondaryFill;

          & p {
            color: #101EA4 !important;
          }
        }

        &:active {
          background-color: $primaryBg;
        }
      }

      &_button {
        width: 100%;
      }

      &_add_btn {
        border-radius: 12px;
        font-size: 16px;
        width: 138px;
        height: 40px;

        & p {
          font-family: Manrope-Regular, sans-serif !important;
        }
      }

      &_select_photos {
        display: none;
      }

      &_photos {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        max-height: 100%;
        overflow-y: auto;

        &_item {
          position: relative;
          width: 100px;
          height: 100px;
          margin-top: 20px;
          margin-right: 20px;
          border-radius: 8px;

          &:hover {
            .temp_container_photos_item_photo {
              opacity: 0.5;
            }

            .temp_container_photos_item_overlay {
              opacity: 1;
            }
          }

          &_photo {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            transition: opacity .4s;
          }

          &_overlay {
            position: absolute;
            top: calc(50% - 20px);
            left: 0;
            opacity: 0;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            cursor: pointer;
            transition: opacity .4s;

            &_icon {
              background-color: $background;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 12px;
              cursor: pointer;
              transition: opacity .2s;

              &:hover {
                background-color: $secondaryFill;

                & img {
                  filter: invert(27%) sepia(95%) saturate(9787%) hue-rotate(218deg) brightness(72%) contrast(149%)
                }
              }
              &:active {
                background-color: $primaryBg;
              }
            }
          }
        }

        &_no_photo {
          color: $textSecondary;
          width: 100%;
          text-align: center;
          font-size: 14px;
          margin-top: 24vh;
        }
      }
    }


    .temp_container_tags_wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;

      .tag_wrapper {
        height: 32px;
        padding: 0 12px;
        margin: 8px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: $secondaryFill;
        border-radius: 12px;
        border: 1px solid $border;
        cursor: pointer;
        transition: background-color .2s, border-color .2s;

        &:hover {
          border-color: $secondaryBorder;
        }

        &:active {
          background-color: $primaryBg;
        }

        &_text {
          font-size: 13px;
          font-family: Manrope-Light, sans-serif;
        }
        &_img {
          position: absolute;
          width: 16px;
          height: 16px;
          top: -8px;
          right: -5px;
        }
      }
    }
  }

  .map_wrapper {
    width: 100%;
    height: 260px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
  }
}

.add_organization_modal {
  width: 460px;
  max-height: 90vh;
  overflow-y: scroll;

  &_button {
    margin: 10px 0 0 auto;
  }
}
