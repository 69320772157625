$primary: #3545DE;
$textPrimary: #061F40;
$darkBlue: #061F40;
$red: #F44336;
$white: #FFFFFF;
$background: #F6F7F9;
$lightBlue: #D7DAF8;
$textSecondary: #7F8A99;
$blueForm: #5379F6;

$dark: #223142;
$primaryBg: #EFF0FC;
$dashedBorder: #85909F;

$border: #e6e8eb;
$primary-hover: #616cd9;
$primary-active: #8189d9;
$secondaryFill: #F6FAFF;
$secondaryBorder: #73ABF8





