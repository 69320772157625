@import './src/helpers/constants';

.report_comments {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  border-bottom: 1px solid $border;

  .report_item_review,
  .report_item_info {
    flex-direction: column;


    &_title {
      font-size: 14px;
      color: $textPrimary;
      margin-bottom: 8px;
    }

    &_subtitle {
      font-size: 14px;
      color: $textSecondary;
      margin-bottom: 24px;
    }

    &_rating {
      display: flex;
      align-items: center;
      margin-top: 12px;

      &_avatar {


        &.filled {

        }

        &.empty {
          filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
        }
      }


      &_date {
        font-size: 14px;
        color: $textSecondary;
        margin-left: 16px;
      }
    }

    &_gallery {
      width: 380px;
      //height: 138px;
      margin-top: 24px;

      &_image {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        margin-right: 8px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    &_description {
      margin-top: 24px;
      width: 380px;
      height: 76px;
      font-size: 14px;
      color: $textPrimary;
    }

    &_block {
      display: flex;

      &_first {
        margin-right: 46px;
      }
    }

  }


  .report_buttons {
    font-size: 16px;
    width: 160px !important;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope-Medium', sans-serif;

    &_secondary {
      border-radius: 12px !important;
      margin-bottom: 12px;
    }

    &_primary {
      margin-bottom: 24px;
    }
  }
}
