@import './src/helpers/constants';

.table_wrapper {
  height: 90vh;

  .per_page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    position: relative;

    &_text {
      font-size: 16px;
      color: $textSecondary;
      margin-right: 16px;
    }

    .dropdown_button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid $border;
      color: $textPrimary;
      background-color: white;
      font-size: 14px;
      cursor: pointer;
      transition: border-color .2s, background-color .2s;

      &:hover {
        border-color: $secondaryBorder;
        background-color: $secondaryFill;
      }

      &:active {
        background-color: $primaryBg;
      }
    }

    .dropdown_wrapper {
      width: 72px;
      height: 112px;
      background-color: white;
      padding: 2px 4px;
      border-radius: 12px;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 2;
      border: 1px solid $border;
      box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
      transition: opacity .2s;

      .dropdown_item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        margin-bottom: 4px;
        width: 64px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid white;
        color: $textPrimary;
        font-size: 14px;
        cursor: pointer;
        transition: border-color .2s, background-color .2s;

        &:hover {
          border-color: $secondaryBorder;
          background-color: $secondaryFill;
        }

        &:active {
          background-color: $primaryBg;
        }
      }

      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .table_top_row {
    margin-right: 196px; //fix
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 54px;

    &_right {
      display: flex;
      align-items: center;
      margin-right: 16px;
      width: 138px;
      height: 40px;
      border-radius: 8px;
    }

    &_button {
      font-size: 16px;
    }
  }

  .table_filters_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    width: 100%;

    &.table_filters_block_second {
      flex-direction: row-reverse;
    }

    .table_filters_row {
      display: flex;
      align-items: center;
      margin-right: 56px;

      .filter_item {
        height: 44px;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 6px;
        position: relative;
        transition: color .2s;

        &:hover:not(.selected) {
          color: $primary;
        }

        &:active:not(.selected) {
          color: lighten($primary, 20);
        }

        &_count {
          padding: 1px 4px;
          background-color: $primaryBg;
          border-radius: 4px;
          display: inline-block;
          margin-left: 8px;
        }

        &.selected {
          &::before {
            content: "";
            width: calc(100% - 16px);
            height: 2px;
            background-color: $blueForm;
            position: absolute;
            bottom: 5px;
            left: 8px;
            border-radius: 4px 4px 0 0;
            animation-name: tableFilterBeforeAppear;
            animation-duration: 0.2s;
          }

          & .filter_item_count {
            color: $primary;
          }
        }
      }
    }
  }

  .secondary_table_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    width: 70%;

    .secondary_table_text {
      font-size: 16px;
      color: $textSecondary;
      margin-right: 16px;
    }

    .secondary_table_input {
      width: 72px;
      height: 40px;
      padding: 4px;
      border-radius: 12px;
      border: 1px solid $border;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .table_scroll {
    height: calc(100% - 116px - 100px);
    overflow-y: auto;
  }

  .table_scroll_text {
    font-family: Manrope-Regular, sans-serif;
    text-align: center;
    color: $textPrimary;
    font-size: 16px;
    margin-top: 48px;
  }

  .table_top_row_left {
    display: flex;
    align-items: center;
  }

}

@keyframes tableFilterBeforeAppear {
  from {
    bottom: 3px
  }
  to {
    bottom: 5px;
  }
}
