@import './src/helpers/constants';

.pagination_wrapper {
  display: flex;
  justify-content: center;
  background-color: $background;
  height: 80px;
  align-items: center;

  .pagination_button {
    width: 32px;
    height: 32px;
    border: 1px solid $border;
    border-radius: 8px;
    background-color: $background;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color .2s, background-color .2s;

    &:hover {
      border-color: $secondaryBorder;
      background-color: $secondaryFill;
    }

    &:active {
      background-color: $primaryBg;

      & img {
        filter: invert(58%) sepia(88%) saturate(336%) hue-rotate(184deg) brightness(101%) contrast(93%);
      }
    }

    &_left {
      margin-right: 36px;
    }
    &_right {
      margin-left: 36px;
    }
  }

  .pagination_page_row {
    display: flex;
    flex-wrap: nowrap;
    max-width: 980px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .pagination_item {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 2px;
    border: 1px solid $background;
    border-radius: 8px;
    background-color: $background;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color .2s, background-color .2s, color .2s;

    &:hover {
      border-color: $secondaryBorder;
      background-color: $secondaryFill;
    }

    &:active {
      background-color: $primaryBg;
      color: $secondaryBorder;
    }

    &.selected {
      border-color: $primary;
      background-color: $primary;
      color: white;
    }
  }
}
