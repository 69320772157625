@import './src/helpers/constants';

.table_cell_item {
  overflow-x: auto;
  white-space: nowrap;
  margin-right: 1%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.is_icon {
    margin-right: 0;
    overflow: visible;
  }

  & .clickable {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: $primaryBg;
    }
    &:active {
      background-color: $secondaryFill;
    }
    &:focus {
      background-color: red;
    }
  }
}
