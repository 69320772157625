@import './src/helpers/constants';

.main {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  height: calc(100vh - 78px);
  padding-top: 100px;

  .block {
    height: 100%;
    width: 360px;
    background-color: white;
    border-radius: 20px;
    padding: 16px 8px;
    flex-shrink: 0;
    box-shadow: 1px 6px 8px 3px #0605330A;
    -webkit-box-shadow: 1px 6px 8px 3px #0605330A;
    -moz-box-shadow: 1px 6px 8px 3px #0605330A;

    &WithScroll {
      overflow-y: auto;
    }

    &Title {
      font-family: Manrope-Regular, sans-serif;
      font-size: 18px;
      color: $textPrimary;
      margin-bottom: 32px;
      text-align: center;
    }

    &Button {
      width: 100%;
    }

    &Info {
      font-family: Manrope-Light, sans-serif;
      font-size: 13px;
      color: $textSecondary;
      margin: 20px 0;
    }

    &ImageRow {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      justify-content: space-between;
    }

    &ImageText {
      font-size: 14px;
    }

    &ImageDiv {
      width: 50px;
      height: 50px;
      background-color: #5379F6;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 30px;
      }
    }
  }

  .list {
    overflow-y: auto;
    margin-right: -100px;
    padding-right: 100px;
    margin-top: 16px;
    height: calc(100% - 120px);

    &EmptyText {
      font-family: Manrope-Light, sans-serif;
      font-size: 18px;
      color: $textSecondary;
      text-align: center;
      margin-top: 60%;
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 16px;
      height: 40px;
      margin-bottom: 16px;
      border-radius: 8px;
      position: relative;

      &Selected {
        background-color: $secondaryFill;
        cursor: default;
        & .listItemText {
          color: $primary;
        }
      }

      &Clickable {
        cursor: pointer;
        transition: background-color .2s;
        &:hover:not(.listItemSelected) {
          background-color: $background;
        }

        &:active {
          background-color: $secondaryFill !important;
        }
      }

      &Text {
        font-family: Manrope-Regular, sans-serif;
        font-size: 16px;
        color: $textPrimary;
      }
    }
  }
}

.modal {
  width: 300px;

  .title {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    text-align: center;
    margin-bottom: 14px;
  }

  .info {
    color: $textSecondary;
    width: 290px;
    text-align: center;
    margin-bottom: 20px;
  }

  .input {
    margin-bottom: 30px;
  }

  .buttonsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & .button_div {
      width: calc(100% - 20px);
    }
  }
}
