@import './src/helpers/constants';

.articles_edit_block_wrapper {
  width: 560px;
  height: calc(100vh - 72px);
  background-color: white;
  border: 1px solid $border;
  border-radius: 24px;
  overflow: auto;
  position: relative;

  .articles_edit_input {
    border: 0;
    outline: none;
    width: 100%;
    min-height: 100%;
    height: fit-content;
    resize: none;
    padding: 10px 16px 0;
    font-size: 16px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    &.non_editable {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      user-select: none;
    }

    &.editable {
      position: relative;
      background: transparent;
      outline: none;
      color: transparent;
      caret-color: black;
      overflow: visible;
    }
  }
}
