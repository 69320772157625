@import './src/helpers/constants';

.copy_tag_wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-left: 12px;

  .copy_tag_button {
    height: 34px;
    width: 70px;
    border-radius: 12px;
    background-color: $primaryBg;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid $primaryBg;
    cursor: pointer;
    transition: border-color .2s, color .2s;

    &:hover:not(.was_copied) {
      border-color: $secondaryBorder;
    }

    &:active:not(.was_copied) {
      color: $secondaryBorder;
    }

    &.was_copied {
      cursor: default;
    }
  }

  .copy_tag_text {
    margin-left: 24px;
  }
}
