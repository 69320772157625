@import './src/helpers/constants';

.icon_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  width: 48px;
  border: 1px solid $border;
  background-color: $background;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color .2s, background-color .2s;

  &:hover:not(.disabled) {
    border-color: $secondaryBorder;
    background-color: $secondaryFill;

    & .icon_button_inner:not(.is_png) {
      filter: invert(58%) sepia(88%) saturate(336%) hue-rotate(184deg) brightness(101%) contrast(93%);
    }
  }
  &:active:not(.disabled) {
    background-color: $primaryBg;
  }

  & .icon_button_inner {
    width: 24px;
    height: 24px;

    &.is_png {
      width: 32px;
      height: 32px;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.6;
  }

  .tooltip_wrapper {
    position: absolute;
    top: 50px;
    max-height: 0;
    opacity: 0;
    box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    transition: max-height .3s, opacity .2s;

    .tooltip_text {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 12px;
      top: 21px;
    }

    &.visible {
      max-height: 49px;
      opacity: 1;
    }
  }
}
