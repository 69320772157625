@import './src/helpers/constants';

.info_table {
  width: 280px;
}

.info_block {
  padding-bottom: 20px;
  .info_block_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    font-size: 14px;
    color: $textSecondary;
    cursor: pointer;
    transition: color .2s, opacity .2s;

    &:hover {
      color: #101EA4;

      & .info_dropdown_img {
        filter: invert(15%) sepia(87%) saturate(4831%) hue-rotate(218deg) brightness(94%) contrast(91%);
        transition: transform .2s;

        &::selection {
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }

    //&:active {
    //  opacity: 0.4;
    //}
  }

  .info_items_list {
    max-height: 300px;
    overflow-y: hidden;
    transition: max-height .2s ease;

    &.wide {
      margin-right: -114px;
      padding-right: 114px;
    }
  }

  .info_item {
    margin-top: 12px;
    background-color: white;
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 12px;
    border: 1px solid white;
    box-shadow: -1px 13px 7px -11px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: -1px 13px 7px -11px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -1px 13px 7px -11px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: background-color .2s, border-color .2s;

    &_icon {
      margin-right: 17px;

      &.disabled_icon {
        filter: invert(48%) sepia(9%) saturate(468%) hue-rotate(181deg) brightness(107%) contrast(92%);
      }
    }

    &_name {
      font-size: 16px;
      color: $textPrimary;
      font-family: Manrope-Regular, sans-serif !important;
    }

    &_toggle {
      position: absolute;
      top: calc(50% - 18px);
      right: -104px;
    }

    &:hover:not(.selected):not(:has(.info_item_toggle:hover)) {
      border-color: $secondaryBorder;
    }

    &:active:not(.selected):not(:has(.info_item_toggle:hover)) {
      background-color: $primaryBg;
    }

    &.selected {
      cursor: default;
      border-color: $secondaryBorder !important;
      background-color: $primaryBg;
    }


    &.dashed {
      border: 1px dashed $dashedBorder;
    }
  }

  &.is_closed {
    .info_items_list {
      max-height: 0 !important;
    }

    .info_dropdown_img {
      transform: rotate(-180deg);
    }
  }
}
