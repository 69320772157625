@import './src/helpers/constants';

.country_info_wrapper {

  .country_info_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .country_info_left {
    display: flex;
    align-items: center;

    .flag_icon {
      margin-left: 16px;
    }

    .hidden_input {
      display: none;
    }
  }

  .country_info_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 192px;
  }

  .country_info_table_part {
    margin-top: -8px;
  }
}
