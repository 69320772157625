@import './src/helpers/constants';


.table_header {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 12px;

  padding: 0 12px;
  margin-top: 32px;

  .table_cell {
    font-size: 13px;
    color: $textSecondary;
    margin-right: 1%;
  }
}
