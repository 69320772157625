@import './src/helpers/constants';

.articles_wrapper {

  .articles_container {
    display: flex;
    justify-content: space-between;

    .articles_list {
      width: 404px;
      padding-left: 10px;
      margin-left: -10px;
      height: calc(100vh - 136px);
      margin-top: 62px;
      overflow-y: auto;
    }
  }
}
