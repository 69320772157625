@import './src/helpers/constants';

.reports_filters_block {
  display: flex;
  align-items: center;
  margin-right: 56px;
  padding-top: 56px;
  width: 100%;

  .table_filters_row {
    display: flex;
    align-items: center;
    margin-right: 56px;

    .filter_item {
      height: 44px;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 6px;
      position: relative;
      transition: color .2s;

      &:hover:not(.selected) {
        color: $primary;
      }

      &:active:not(.selected) {
        color: lighten($primary, 20);
      }

      &_count {
        display: flex;
        margin-left: 8px;
        font-size: 16px;
        color: $textPrimary;
        background-color: #EFF0FC;
        padding: 0 4px 0 4px;
        border-radius: 4px;

        &.selected {
       
          color: $primary;


        }
      }


      &.selected {
        &::before {
          content: "";
          width: calc(100% - 16px);
          height: 2px;
          background-color: $blueForm;
          position: absolute;
          bottom: 5px;
          left: 8px;
          border-radius: 4px 4px 0 0;
          animation-name: tableFilterBeforeAppear;
          animation-duration: 0.2s;
        }
      }
    }
  }
}