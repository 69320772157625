@import './src/helpers/constants';

.toggle_wrapper {
  width: 68px;
  height: 36px;
  border-radius: 18px;
  background-color: #C3C8F6;
  cursor: pointer;
  padding: 4px;
  transition: background-color .2s;

  &.toggle_disabled {
    cursor: default;
    background-color: #F0F0F2;
  }

  &.toggle_selected {
    background-color: $primary;
  }

  &:hover:not(.toggle_selected):not(.toggle_disabled) {
    background-color: $secondaryBorder;
  }

  & .toggle_inner {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: white;

    box-shadow: 0 6px 6px -6px rgba(0,0,0,0.16);
    -webkit-box-shadow: 0 6px 6px -6px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 6px 6px -6px rgba(0,0,0,0.16);
    transition: transform .2s ease;

    &_selected {
      transform: translateX(32px);
    }
  }
}
