@import './src/helpers/constants';

.admin_modal {
  width: 449px;

  .modal_top_part {
    height: 642px;
  }

  .modal_title {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    height: 40px;
    text-align: center;
    margin: 0 auto;
  }

  .modal_info_name {
    text-decoration: underline;
    color: $textSecondary;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
  }

  .modal_info {
    color: $textSecondary;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
  }

  .modal_buttons_row {
    //width: 340px;
    display: flex;
    justify-content: space-between;

    .modal_button {
      width: calc(50% - 10px);
    }

    &.buttons_with_margin {
      margin-top: 56px;
    }
  }

  .modal_checkboxes_block {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .modal_simple_block {
    margin-top: 24px;

    &.margin {
      margin-top: 36px;
    }

    &_title {
      height: 22px;
      font-size: 18px;
      font-family: Manrope-Medium, sans-serif;
    }

    &_descr {
      margin-top: 4px;
      color: $textSecondary;
      font-size: 13px;
      height: 26px;
    }

    &_error {
      margin-top: 8px;
      color: $red;
      font-size: 13px;
      margin-bottom: -10px;
    }


    &_text {
      height: 22px;
      margin-top: 12px;
    }
  }

  .modal_input {
    margin-top: 24px;
  }

  .modal_countries_scroll {
    margin-top: 8px;
    height: 378px;
    width: 100%;
    border: 1px solid $border;
    border-radius: 12px;
    padding: 8px 4px;
    overflow-y: scroll;
  }
  .modal_countries_checkbox {
    height: 48px;
    margin-bottom: 4px;
    border-radius: 16px;
    padding: 0 20px;
    transition: background-color .3s;

    &:hover:not(.disabled) {
      background-color: $primaryBg;
      opacity: 1 !important;
    }
  }

  .modal_simple_confirm_countries {
    display: flex;
    align-items: center;

    &_item {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  }
}
