@import './src/helpers/constants';

.button_div {
  //width: 100%;

  width: 160px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s;


  &.disabled_button {
    background-color: $lightBlue;
    cursor: default;
  }

  &:hover:not(.disabled_button) {
    background-color: #101EA4;
  }

  &:active:not(.disabled_button):not(.secondary) {
    background-color: #101EA4;
    box-shadow: inset 5px 5px 6px 0 rgba(4, 7, 36, 0.25);
    -webkit-box-shadow: inset 5px 5px 6px 0 rgba(4, 7, 36, 0.25);
    -moz-box-shadow: inset 5px 5px 6px 0 rgba(4, 7, 36, 0.25);
  }

  .button_icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;

    &::selection {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_text {
    color: white;
    font-family: 'Manrope-Medium', sans-serif;
    transition: color .2s;
  }

  .disabled {
    opacity: 0;
  }

  &.secondary {
    background-color: white;
    border: 1px solid $primary;

    .button_text {
      font-family: 'Manrope-Regular', sans-serif;
      color: $primary;
    }

    &:hover:not(.disabled_button) {
      background-color: $secondaryFill;
      border: 1px solid #101EA4;

      .button_text {
        color: #101EA4;
      }
    }

    &:active:not(.disabled_button) {
      background-color: $primaryBg;
      border: 1px solid #101EA4;
      color: #101EA4 !important;;
    }
  }
}
