@import './src/helpers/constants';

.input_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E6E8EB;
  border-radius: 12px;
  padding: 0 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover{
    background-color: $background;
    border: 1px solid $secondaryBorder;
  }

  &:active,
  &.is_active {
    background-color: $primaryBg;
    transform: translateY(0);
  }

  &_input {
    width: 360px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover{
      background-color: transparent;
    }

    &:active{
      background-color: transparent;
    }
  }
}
