@import './src/helpers/constants';

.navbar {
  min-width: 240px;
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_title {
    color: $primary;
    font-family: Manrope-Regular, sans-serif;
    font-size: 32px;
    text-align: center;
  }

  &_country {
    height: 32px;
    width: 147px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin: 15px 0 0 64px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    transition: background-color .2s;

    &.single {
      cursor: default;

      & .navbar_country_drop {
        display: none;
      }
    }

    &:hover:not(.single) {
      & .navbar_country_name {
        color: $secondaryBorder;
      }

      & .navbar_country_drop {
        filter: invert(58%) sepia(88%) saturate(336%) hue-rotate(184deg) brightness(101%) contrast(93%);
      }
    }

    &:active:not(.single) {
      background-color: $primaryBg;
    }

    &_flag {
      width: 24px;
      height: 24px;
    }

    &_name {
      color: $textSecondary;
      font-size: 13px;
      margin: 0 8px;
      transition: color .2s;
    }

    &_drop {
    }


    .popup_for_dropdown {
      width: 139px;
      border-radius: 8px;
      right: 4px;
      top: 0;
      z-index: 100;

      & .table_popup_item {
        height: 32px;
        border-radius: 4px;
        padding-left: 8px;

        &:active {
          & img {
            filter: unset;
          }
        }
      }

      & img {
        width: 24px;
        height: 24px;
      }

      & p {
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }


  &_top {
    width: 100%;
    display: flex;
    flex-direction: column;
  }


  &_list {
    margin-top: 50px;
    list-style: none;

    &_item {
      margin-bottom: 16px;
      height: 48px;
      position: relative;
    }
  }

  &_item {
    display: flex;
    text-decoration: none;
    color: $textPrimary;
    border-radius: 16px;
    padding: 12px 20px;
    margin: 0 4px;
    position: relative;
    transition: background-color .2s;

    &:hover:not(.selected) {
      background-color: $primaryBg;
    }

    &:active:not(.selected) {
      background-color: darken($primaryBg, 5);
    }

    &.selected {
      cursor: default;
      .navbar_item_text {
        color: $primary;
      }

      &::before {
        content: "";
        width: 4px;
        height: 24px;
        background-color: $primary;
        position: absolute;
        top: calc(50% - 12px);
        left: -4px;
        border-radius: 0 8px 8px 0;
        animation-name: sideBarBeforeAppear;
        animation-duration: 0.2s;
      }

      & + .navbar_item_count {
        color: $primary;
      }
    }

    &_icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_img {
        width: 100%;
        height: 100%;
      }
    }

    &_text {
      font-family: Manrope-Regular, sans-serif;
      font-size: 18px;
      color: $textPrimary;
      margin-left: 16px;
    }

    &_count {
      font-family: Manrope-Regular, sans-serif;
      color: $textPrimary;
      position: absolute;
      right: 30px;
      top: 13px;

      font-size: 16px;
      background-color: #EFF0FC;
      padding: 0 4px 0 4px;
      border-radius: 4px;
      cursor: default;
    }
  }

  &_logout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 24px;
    height: 56px;

    &_name {
      font-size: 13px;
      color: $textSecondary;
      font-family: Manrope-Regular, sans-serif;
    }

    &_bold {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      width: 160px;
      display: block;
      color: $textPrimary;
      font-family: Manrope-Regular, sans-serif;
      margin-bottom: 8px;
    }

    &_button {
      background-color: white;
      border-color: white;
    }
  }
}

@keyframes sideBarBeforeAppear {
  from {
    left: -8px
  }
  to {
    left: -4px;
  }
}

