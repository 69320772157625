@import './src/helpers/constants';

.report_organizations {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  border-bottom: 1px solid $border;

  .report_item_review,
  .report_item_info {
    flex-direction: column;


    &_title {
      font-size: 14px;
      color: $textPrimary;
      margin-bottom: 8px;
    }

    &_subtitle {
      font-size: 14px;
      color: $textSecondary;
      margin-bottom: 24px;
    }

    &_gallery {
      width: 380px;
      height: 138px;
      margin-top: 24px;

      &_image {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        margin-right: 8px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    &_description {
      width: 380px;
      height: 76px;
      font-size: 14px;
      color: $textPrimary;
    }

    &_block {
      display: flex;

      &_first {
        margin-right: 46px;
      }
    }

  }


  .report_buttons {
    font-size: 16px;
    width: 160px;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope-Medium', sans-serif;


    &_primary {
      margin-bottom: 24px;
    }
  }
}
