@import './src/helpers/constants';

.edit_organization_photos {
  display: flex;

  &_info_text {
    margin-top: 80px;
  }

  &_button_group {
    display: flex;
    align-items: center;

    &_cancel {
      width: 160px;
      height: 48px;
      margin-right: 24px;
    }

    &_save {
      width: 160px;
      height: 48px;
    }
  }

  &_container {
    width: 556px;
    height: calc(100vh - 136px);
    background-color: #FFFFFF;
    margin-top: 74px;
    border-radius: 20px;
    padding: 20px;
    overflow-y: auto;

    .photos_container {
      height: 100%;

      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &_btn {
          background-color: white;
          margin-left: 22px;
        }

        &.second {
          margin-top: 30px;
        }
      }

      &_title {
        font-family: Manrope-Light, sans-serif;
        font-size: 18px;
        margin-top: 16px;
        margin-bottom: 12px;
        text-align: center;
      }

      &_description {
        font-family: Manrope-Light, sans-serif;
        font-size: 13px;
        margin-top: 16px;
        margin-bottom: 12px;
      }

      &_scroll {
        height: calc(100% - 70px);
        overflow-y: auto;

        &.with_button {
          height: calc(100% - 123px);
          margin-bottom: 14px;
        }
      }

      &_item {
        margin: 8px 0;
        padding: 0 16px;
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 1 !important;
        transition: background-color .2s;

        &_text {
          font-family: Manrope-Light, sans-serif;
          font-size: 16px;
          transition: color .2s;
        }

        &_img {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        &:hover {
          background-color: $secondaryFill;

          & p {
            color: #101EA4 !important;
          }
        }

        &:active {
          background-color: $primaryBg;
        }
      }

      &_button {
        width: 100%;
      }

      &_add_btn {
        border-radius: 12px;
        font-size: 16px;
        width: 138px;
        height: 40px;

        & p {
          font-family: Manrope-Regular, sans-serif !important;
        }
      }

      &_select_photos {
        display: none;
      }

      &_photos {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        max-height: 100%;

        &_item {
          position: relative;
          width: 100px;
          height: 100px;
          margin-top: 20px;
          margin-right: 20px;
          border-radius: 8px;

          &:hover {
            .photos_container_photos_item_photo {
              opacity: 0.5;
            }

            .photos_container_photos_item_overlay {
              opacity: 1;
            }
          }

          &_photo {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            transition: opacity .4s;
          }

          &_overlay {
            position: absolute;
            top: calc(50% - 20px);
            left: 0;
            opacity: 0;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            transition: opacity .4s;

            &_icon {
              background-color: $background;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 12px;
              cursor: pointer;
              transition: opacity .2s;

              &:hover {
                background-color: $secondaryFill;

                & img {
                  filter: invert(27%) sepia(95%) saturate(9787%) hue-rotate(218deg) brightness(72%) contrast(149%)
                }
              }
              &:active {
                background-color: $primaryBg;
              }
            }
          }
        }

        &_no_photo {
          color: $textSecondary;
          width: 100%;
          text-align: center;
          font-size: 14px;
          margin: 50.5px 0;
        }
      }
    }
  }
}

.edit_organization_photos_modal {
  .modal_title {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    height: 40px;
    text-align: center;
    margin: 0 auto;
  }

  .modal_info_name {
    text-decoration: underline;
    color: $textSecondary;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
  }

  .modal_info {
    color: $textSecondary;
    width: 270px;
    text-align: center;
    margin: 20px auto 0;
    height: 66px;
  }

  .modal_error {
    color: $red;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
    height: 66px;
  }

  .modal_photo {
    width: 100px;
    height: 100px;
    margin: 20px auto 0;
    object-fit: cover;
    display: block;
  }

  .modal_photo_big {
  }

  .modal_buttons_row {
    width: 340px;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;

    &.one_button {
      justify-content: center;
    }

    .modal_button {
      width: calc(50% - 10px);

      &.single {
        margin: 0 auto;
      }
    }
  }

  .modal_simple_block {
    margin-top: 32px;

    &_title {
      height: 22px;
    }

    &_descr {
      margin-top: 4px;
      color: $textSecondary;
      font-size: 13px;
      height: 26px;
    }
  }

  .modal_input {
    margin-top: 24px;
  }
}
