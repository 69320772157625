@import './src/helpers/constants';

.articles_modal {
  .modal_title {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    height: 40px;
    text-align: center;
    margin: 0 auto;
  }
  .modal_info_name {
    text-decoration: underline;
    color: $textSecondary;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
  }
  .modal_info {
    color: $textSecondary;
    width: 270px;
    text-align: center;
    margin: 20px auto 0;
    height: 66px;
  }
  .modal_error {
    color: $red;
    width: 290px;
    text-align: center;
    margin: 20px auto 0;
    height: 66px;
  }

  .modal_buttons_row {
    width: 340px;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;

    .modal_button {
      width: calc(50% - 10px);

      &.single {
        margin: 0 auto;
      }
    }
  }

  .modal_simple_block {
    margin-top: 32px;

    &_title {
      height: 22px;
    }

    &_descr {
      margin-top: 4px;
      color: $textSecondary;
      font-size: 13px;
      height: 26px;
    }
  }

  .modal_input {
    margin-top: 24px;
  }
}
