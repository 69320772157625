@import './src/helpers/constants';

.checkbox_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  margin-bottom: 2px;
  transition: opacity .2s;

  &:hover:not(.disabled) {
    opacity: 0.7;
  }
  &:active:not(.disabled) {
    opacity: 0.3;
  }

  .checkbox_text {
    margin-left: 16px;

    &::selection {
      background-color: white;
    }
  }

  .checkbox_img {
    width: 32px;
    height: 32px;
    margin-left: 24px;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
}
