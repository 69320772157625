@import './src/helpers/constants';

.table_popup {
  position: absolute;
  width: 264px;
  padding: 4px 8px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid $border;
  right: 4px;
  top: 8px;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  transition: opacity .2s;

  &_item {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 16px;
    margin: 4px 0;
    transition: background-color .2s;

    &:hover {
      background-color: $secondaryFill;
    }

    &:active {
      background-color: $primaryBg;

      & p {
        color: $primary;
      }

      & img {
        filter: invert(15%) sepia(87%) saturate(4831%) hue-rotate(218deg) brightness(94%) contrast(91%);
      }
    }

    & p {
      margin-left: 8px;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.user_item_index {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
