@import './src/helpers/constants';


.page_title {
  display: flex;
  justify-content: space-between;
  position: absolute;

  &.wide {
    width: calc(100vw - 312px);
    min-width: 968px;
  }

  &_text {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    color: $textPrimary;
  }
}
