@import './src/helpers/constants';


.table_row {
  width: 100%;
  height: 48px;

  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-top: 4px;
  padding: 0 12px;
  border: 1px solid white;
  border-bottom-color: $border;
  transition: border-color .2s;

  &:hover {
    border: 1px solid $secondaryBorder;
  }

  &.dashed {
    border: 1px dashed $dashedBorder;
  }
}
