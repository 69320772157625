
.admin_table_add_btn {
  border-radius: 12px;
  font-size: 16px;
  width: 138px;
  height: 40px;

  & p {
    font-family: Manrope-Regular, sans-serif !important;
  }
}
