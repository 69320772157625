@import './src/helpers/constants';

.articles_view_block_wrapper {
  width: 360px;
  height: calc(100vh - 72px);
  background-color: white;
  border: 1px solid $border;
  border-radius: 24px;
  overflow-y: auto;

  .articles_view_block_no_info {
    overflow: hidden;

    &_text {
      margin-top: 64px;
      font-size: 18px;
      color: $textSecondary;
      text-align: center;
    }

    &_img {
      width: 100%;
      margin-top: 16px;
      max-height: 360px;
    }
  }

  .articles_view_block_main {
    padding: 10px 16px;
    overflow-y: auto;
    color: $dark;
    font-family: Manrope-Regular, sans-serif;

    h1 {
      font-size: 24px;
      font-family: Manrope-Medium, sans-serif;
    }

    h2 {
      font-size: 20px;
      margin-top: 16px;
      font-family: Manrope-SemiBold, sans-serif;
    }

    h3 {
      font-size: 16px;
      margin-top: 16px;
      font-family: Manrope-SemiBold, sans-serif;
    }

    h4 {
      font-size: 16px;
      margin-top: 8px;
      color: #8B96A3;
    }

    pre {
      font-size: 16px;
      margin-top: 16px;
      text-wrap: initial;
    }

    b {
      font-family: Manrope-SemiBold, sans-serif;
      font-weight: bold;
    }

    a {
      color: $primary;
      text-decoration: none;
    }

    ul {
      padding-left: 20px;
    }

    li {
      font-size: 16px;
      margin-top: 16px;
    }

    img {
      border-radius: 20px;
      overflow: hidden;
      margin-top: 16px;
      width: 100%;
    }

    sub {
      display: block;
    }
  }
}
