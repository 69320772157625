@import './src/helpers/constants';

.articles_buttons_wrapper {

  .buttons_row {
    display: flex;

    .article_button {
      margin: 0 12px;
    }
  }

  .article_tags_list {
    margin-top: 44px;
  }
}
