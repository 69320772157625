@import './src/helpers/constants';

.countries_top_row {
  display: flex;
  align-items: center;

  .countries_top_input {
    width: 506px;
  }
}
.countries_add_btn {
  width: 200px;
  margin-left: 32px;
}

.countries_wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 96px;
  height: calc(100vh - 72px);

  .countries_scroll {
    width: 300px;
    height: 100%;
    overflow-y: auto;
  }

  .cities_table {
    margin-left: 80px;
    flex: 1
  }
}

