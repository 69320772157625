@font-face {
  font-family: "Manrope-Bold";
  /*src: local('Manrope'), url("../public/fonts/Manrope/Manrope-SemiBold.ttf") format('truetype');*/
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-SemiBold";
  /*src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Medium.ttf") format('truetype');*/
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Medium";
  /*src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Regular.ttf") format('truetype');*/
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Regular";
  /*src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Light.ttf") format('truetype');*/
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Light";
  /*src: local('Manrope'), url("../public/fonts/Manrope/Manrope-ExtraLight.ttf") format('truetype');*/
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Light.ttf") format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Manrope-Regular, sans-serif;
  color: #061F40;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

input, textarea {
  font-size: 16px;
  color: #061F40;
  border: none;
  outline: none;
  resize: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::placeholder {
  color: #7F8A99;
}

.row {
  display: flex;
}
