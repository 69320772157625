@import './src/helpers/constants';

.country_item_wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid white;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: border-color .2s, background-color .2s;

  &:hover:not(.country_item_wrapper_selected) {
    border-color: $secondaryBorder;
    background-color: $secondaryFill;
  }
  &:active:not(.country_item_wrapper_selected) {
    background-color: $primaryBg;
  }

  & img {
    width: 32px;
    height: 32px;
    margin: 0 24px;
  }

  & .img_placeholder {
    width: 32px;
    height: 32px;
    margin: 0 24px;
  }

  &.country_item_wrapper_selected {
    cursor: default;
    border-color: $secondaryBorder;
    background-color: $primaryBg;
  }
}
