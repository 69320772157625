@import './src/helpers/constants';

.gallery_wrapper {

  .gallery_add_btn {
    border-radius: 12px;
    font-size: 16px;
    width: 138px;
    height: 40px;

    & p {
      font-family: Manrope-Regular, sans-serif !important;
    }
  }

  .gallery_select_photos {
    display: none;
  }


  .gallery_container {
    display: flex;
    align-items: flex-start;
    padding-top: 62px;
    height: calc(100vh - 72px);

    .gallery_left {
      width: 300px;
      padding-left: 10px;
      margin-left: -10px;
      height: 100%;
      overflow-y: auto;
      overflow-x: visible;
    }

    .gallery_table {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-height: 100%;
      margin-left: 46px;
      overflow-y: auto;

      .gallery_table_item {
        position: relative;
        width: 140px;
        height: 140px;
        margin-bottom: 70px;
        margin-right: 16px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          .gallery_table_item_photo {
            opacity: 0.5;
          }

          .gallery_item_overlay {
            opacity: 1;
          }
        }

        &_photo {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
          transition: opacity .4s;
        }

        &_title {
          text-align: center;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          width: 140px;
          word-wrap: break-word;
          //height: 43px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .gallery_item_overlay {
          position: absolute;
          top: calc(50% - 20px);
          left: 0;
          opacity: 0;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          transition: opacity .4s;

          &_icon {
            background-color: $background;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 12px;
            cursor: pointer;
            transition: opacity .2s;

            &:hover {
              background-color: $secondaryFill;

              & img {
                filter: invert(27%) sepia(95%) saturate(9787%) hue-rotate(218deg) brightness(72%) contrast(149%)
              }
            }
            &:active {
              background-color: $primaryBg;
            }
          }
        }
      }

      .gallery_no_photo {
        color: $textSecondary;
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 40vh;
      }
    }
  }
}
