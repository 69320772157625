@import './src/helpers/constants';


.login_wrapper {
  background-color: $background;
  height: 100vh;

  .registration_form_div {
    border: 1px solid $border;
    width: 400px;
    height: 480px;
    position: absolute;
    padding: 24px 20px;
    background-color: #fff;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .hidden_input {
      display: none;
    }
  }

  .form__title {
    font-family: Manrope-Light, sans-serif;
    text-align: center;
    font-size: 32px;
    margin-bottom: 63px;
    margin-top: 16px;
    color: $primary;
  }

  .login_button {
    width: 360px;
    margin-top: 91px;
  }
}
